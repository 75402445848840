import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-atlanta',
    predictionPlace: {
      address: 'Atlanta, GA',
      bounds: new LatLngBounds(new LatLng(34.174, -84.049), new LatLng(33.329, -84.827)),
    },
  },
  {
    id: 'default-augusta',
    predictionPlace: {
      address: 'Augusta, GA',
      bounds: new LatLngBounds(new LatLng(33.544934, -81.828336), new LatLng(33.244257, -82.165345)),
    },
  },
  {
    id: 'default-austin',
    predictionPlace: {
      address: 'Austin, TX',
      bounds: new LatLngBounds(new LatLng(30.519, -97.541), new LatLng(30.067, -98.076)),
    },
  },
  {
    id: 'default-boston',
    predictionPlace: {
      address: 'Boston, MA',
      bounds: new LatLngBounds(new LatLng(42.728, -70.641), new LatLng(41.966, -71.501)),
    },
  },
  {
    id: 'default-buffalo',
    predictionPlace: {
      address: 'Buffalo, NY',
      bounds: new LatLngBounds(
        new LatLng(44.2691456, -77.71289455),
        new LatLng(41.84860236, -80.36962097)
      ),
    },
  },
  {
    id: 'default-charlotte',
    predictionPlace: {
      address: 'Charlotte, NC',
      bounds: new LatLngBounds(new LatLng(35.40261, -80.555397), new LatLng(35.001712, -81.058199)),
    },
  },
  {
    id: 'default-chicago',
    predictionPlace: {
      address: 'Chicago, IL',
      bounds: new LatLngBounds(new LatLng(42.035, -87.51), new LatLng(41.644, -87.869)),
    },
  },
  {
    id: 'default-denver',
    predictionPlace: {
      address: 'Denver, CO',
      bounds: new LatLngBounds(
        new LatLng(40.38342542, -104.26428481),
        new LatLng(39.10551497, -105.59690433)
      ),
    },
  },
  {
    id: 'default-fortlauderdale',
    predictionPlace: {
      address: 'Fort Lauderdale, FL',
      bounds: new LatLngBounds(new LatLng(26.31116565, -79.95205749), new LatLng(25.94862189, -80.26652439)),
    },
  },
  {
    id: 'default-houston',
    predictionPlace: {
      address: 'Houston, TX',
      bounds: new LatLngBounds(
        new LatLng(30.085555, -95.054091),
        new LatLng(29.5231884, -95.730396)
      ),
    },
  },
  {
    id: 'default-losangeles',
    predictionPlace: {
      address: 'Los Angeles, CA',
      bounds: new LatLngBounds(new LatLng(34.161439, -118.126728), new LatLng(33.899991, -118.52144)),
    },
  },
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami, FL',
      bounds: new LatLngBounds(new LatLng(25.967, -80.026), new LatLng(25.611, -80.345)),
    },
  },
  {
    id: 'default-oc',
    predictionPlace: {
      address: 'Orange County, CA',
      bounds: new LatLngBounds(
        new LatLng(34.92916436, -117.09200064),
        new LatLng(32.98410692, -118.89321106)
      ),
    },
  },
  {
    id: 'default-palmbeach',
    predictionPlace: {
      address: 'Palm Beach, FL',
      bounds: new LatLngBounds(new LatLng(27.04300466, -79.71133935), new LatLng(26.3213848, -80.34027315)),
    },
  },
  {
    id: 'default-phoenix',
    predictionPlace: {
      address: 'Phoenix, AZ',
      bounds: new LatLngBounds(new LatLng(34.1610096, -111.5745206), new LatLng(32.99805397, -112.6616024)),
    },
  },
  {
    id: 'default-stlouis',
    predictionPlace: {
      address: 'St. Louis, MO',
      bounds: new LatLngBounds(new LatLng(39.05282381, -89.87995943), new LatLng(38.16541822, -90.75042457)),
    },
  },
  {
    id: 'default-tampa',
    predictionPlace: {
      address: 'Tampa, FL',
      bounds: new LatLngBounds(
        new LatLng(28.87367722, -81.68801935),
        new LatLng(27.11184324, -83.22731975)
      ),
    },
  },
];
export default defaultLocations;
